// src/components/Testimonials.js
import React from "react";
import "./Testimonials.css";

const Testimonials = () => {
  const TestimonialCard = ({ testimonial, name, title, image }) => {
    return (
      <div className="testimonial-card">
        <p className="testimonial">{testimonial}</p>
        <div className="testimonial-author">
          <p className="author-name">{name}</p>
          <div>
            <p className="author-title">{title}</p>
          </div>
        </div>
      </div>
    );
  };

  const testimonials = [
    {
      testimonial:
        "Our company's HR processes have never been smoother. The comprehensive hiring support and payroll management features have saved us countless hours. We highly recommend this HR solutions platform!",
      name: "Ankit",
      title: "HR Manager",
      image: "/path/to/image1.jpg",
    },
    {
      testimonial:
        "This HR solutions platform has revolutionized our HR department. The intuitive interface and exceptional customer service have helped us streamline operations and focus on strategic initiatives. It's a must-have for any organization.",
      name: "yuvraj",
      title: "HR Director",
      image: "/path/to/image2.jpg",
    },
    {
      testimonial:
        "Choosing this HR solutions provider was one of the best decisions for our company. The integrated HRMS and vendor services have significantly improved our efficiency and compliance. Plus, their support team is always ready to assist.",
      name: "Arun",
      title: "Operations Manager",
      image: "/path/to/image3.jpg",
    },
    {
      testimonial:
        "We've used several HR platforms in the past, but none compare to this one. The seamless payroll management and vendor integration have boosted our productivity and reduced errors. Highly recommended!",
      name: "Viswa",
      title: "Financial Analyst",
      image: "/path/to/image4.jpg",
    },
    {
      testimonial:
        "This HR solutions platform has been a game-changer for us. It has automated our repetitive tasks, improved our workflows, and enhanced communication across the organization. We couldn't be happier with the results.",
      name: "Mark",
      title: "HR Specialist",
      image: "/path/to/image5.jpg",
    },
    {
      testimonial:
        "Partnering with this HR solutions company has been instrumental in achieving our business goals. The user-friendly platform and stellar customer service have made managing HR functions a breeze.",
      name: "Venu",
      title: "IT Manager",
      image: "/path/to/image6.jpg",
    },
  ];

  return (
    <section id="testimonials">
      <div className="testimonial-app">
        <h1>Client Success Stories</h1>
        <p className="subtitle">
          Sharing Their Successes and Transformative Experiences with
          CloudPeak's Powerful SaaS Solutions
        </p>
        <div className="testimonials">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard
              key={index}
              testimonial={testimonial.testimonial}
              name={testimonial.name}
              title={testimonial.title}
              image={testimonial.image}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
