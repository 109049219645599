import React from "react";
import "./FormDemo.css";

const FormDemo = () => {
  return (
    <section id="contact">
      <div className="form-container">
        <div>
        <h2>Want a Free Demo?</h2>
        <p>
          Experience the Benefits Firsthand with a Free Personalized Demo. Fill
          out the form and we will reach out to you.
        </p>
        </div>
        <form className="form">
          <div className="form-group">
            <label htmlFor="firstName">First Name *</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              placeholder="Your first name"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name *</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              placeholder="Your last name"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email *</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="youremail@gmail.com"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone Number *</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Your phone number"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="subject">Subject *</label>
            <input
              type="text"
              id="subject"
              name="subject"
              placeholder="Write your subject"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Write Message *</label>
            <textarea
              id="message"
              name="message"
              placeholder="Write your message here"
              required
            ></textarea>
          </div>
          <button type="submit">Submit your Request</button>
        </form>
      </div>
    </section>
  );
};


export default FormDemo;
