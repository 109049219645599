// src/components/AboutUs.js
import React,{useState} from "react";
import "./AboutUs.css";
import Card from "./card";
// import { OurMission } from "./ourMission";


const AboutUs = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const cards = [
    {
      title: "Support for Hiring",
      description: "Our hiring solutions cover the entire lifecycle of placement, from screening to onboarding. We help you find the right talent and ensure a smooth and efficient onboarding process."
    },
    {
      title: "Vendor Services",
      description: "We offer robust vendor management solutions that allow you to effectively manage and integrate with your vendors, ensuring seamless operations and collaboration."
    },
    {
      title: "Payroll Management",
      description: "Our payroll solutions are designed to simplify payroll processing, ensuring accuracy, compliance, and timely payments. Manage employee compensation with ease and efficiency."
    },
    {
      title: "HRMS",
      description: "Our HRMS (Human Resource Management System) provides a comprehensive suite of tools to manage all your HR functions in one place. From employee records to performance management, our HRMS helps you streamline your HR processes and improve productivity."
    }
  ];

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <section id="about-us">
      <div className="about-content">
        <h2>About Our HR Solutions</h2>
        <div className="carousel">
          <div className="cards-wrapper">
            {cards.slice(currentIndex, currentIndex + 2).map((card, index) => (
              <Card key={index} title={card.title} description={card.description} />
            ))}
          </div>
          <div className="dots">
            {cards.map((_, index) => (
              <span
                key={index}
                className={`dot ${currentIndex === index ? 'active' : ''}`}
                onClick={() => handleDotClick(index)}
              ></span>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
