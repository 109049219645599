import React, { useState } from "react";
import "./FaqFooter.css";
// import Card from "./card";
const FAQFooter = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "What is your HR solutions platform?",
      answer:
        "Our HR solutions platform is a comprehensive system designed to streamline HR operations, from hiring and onboarding to payroll management and vendor services. It's an all-in-one solution to enhance efficiency and productivity.",
    },
    {
      question: "What services does your HR solutions platform offer?",
      answer:
        "Our platform offers a wide range of services, including support for hiring, vendor management, payroll processing, and a fully integrated Human Resource Management System (HRMS).",
    },
    {
      question: "What are the core features of your HR solutions platform?",
      answer:
        "The core features include end-to-end hiring support, robust vendor management, seamless payroll processing, and a comprehensive HRMS that covers all aspects of HR management.",
    },
    {
      question: "How can your HR solutions platform help my business?",
      answer:
        "Our platform can help your business by automating and streamlining HR processes, reducing administrative burdens, improving compliance, and enhancing overall efficiency. This allows your HR team to focus on strategic initiatives that drive business growth.",
    },
    {
      question: "Is your HR solutions platform suitable for businesses of all sizes?",
      answer:
        "Yes, our HR solutions platform is designed to be scalable and flexible, making it suitable for businesses of all sizes, from small startups to large enterprises. It can be customized to meet the specific needs of your organization.",
    },
  ];
  

  return (
    <>
      <div className="faq-footer">
        <div className="faq-section">
          <div className="faq-description">
            <h2>Frequently Asked Questions</h2>
            <p>
              Haven't found what you're looking for? <a href="#Contact">Contact us</a>
            </p>
          </div>
          <div className="faqs">
            {faqs.map((faq, index) => (
              <div key={index} className="faq">
                <div className="faq-question" onClick={() => toggleFAQ(index)}>
                  <span>{faq.question}</span>
                  <span>{openIndex === index ? "-" : "+"}</span>
                </div>
                {openIndex === index && (
                  <div className="faq-answer">{faq.answer}</div>
                )}
              </div>
            ))}
          </div>
        </div>
        {/* <div className="faq-section">
        <div className="faq-description">
          <h2>Get In Touch with us</h2>
          <p>
            Haven't found what you're looking for? <a href="#Contact">Contact us</a>
          </p>
        </div>
        <div className="faqs">
          <Card>
            <form>
              <label>email ID</label>
              <input />
              <label>email ID</label>
              <input />
              <label>email ID</label>
              <input />
            </form>
          </Card>
        </div>
      </div> */}
      </div>
      <div className="footer">
          {/* <div className="hero-background"></div> */}
        <div className="footer-top">
          <span className="footer-logo">Tech Butterfly Solutions{""} | {""}
           that drive success and propel your business forward
          </span>
          <div className="footer-links">
            <a href="#Contact">Vendors Login</a>
            <a href="#Contact">HRMS</a>
            <a href="#Contact">Pricing</a>
            <a href="#Contact">Contact Us</a>
          </div>
        </div>
        <div className="footer-bottom">
          <span>Copyright © 2024. All Rights Reserved</span>
          <div className="footer-bottom-links">
            <a href="#Contact">Privacy Policy</a>
            <a href="#Contact">Terms of Use</a>
          </div>
          <div className="footer-icons">
            <a href="#Contact">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="#Contact">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="#Contact">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="#Contact">
              <i className="fab fa-linkedin-in"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQFooter;
