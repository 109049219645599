import "./ourMission.css";
export const OurMission = () => {
  const Card = ({ title, description, additionalContent }) => {
    return (
      <div className="j-card">
        <h2 className="j-card-title">{title}</h2>
        <p className="j-card-description">{description}</p>
        <div className="j-card-content">
          <div className="j-card-background-lines"></div>
          {additionalContent}
        </div>
      </div>
    );
  };

  return (
    <section id="OurMission">
      <div>
        <h1>
          Our Journey and Mission{" "}
          <span style={{display: 'block', padding: "10px"}}> 
            Explore Our Inspirational Journey, Values, and Commitment to
            Providing Exceptional HR Solutions That Empower Your Business
          </span>
        </h1>
      </div>
      <div className="M-card">
        <Card
          title="Seamless Integration"
          description="Tech Butterfly Solutions Flexible Integration Options for Enhanced Efficiency."
          additionalContent={
            <>
              <div className="highlight-text">200%</div>
              <div className="highlight-description">Faster Integration</div>
              <div className="AdditionalContent">
                <img
                  src="/path/to/your/integration-graphic.png"
                  alt="Integration Graphic"
                  className="graphic"
                />
                <p>
                  Our mission is to empower individuals and organizations to
                  unleash their full potential by providing cutting-edge HR
                  solutions.
                </p>
              </div>
            </>
          }
        />
        <Card
          title="Scalable Infrastructure"
          description="Tech Butterfly Solutions Scalable Infrastructure Can Handle Your Increasing Demands and Workloads."
          additionalContent={
            <>
              <div className="highlight-text">120k+</div>
              <div className="highlight-description">
                Happy customers worldwide
              </div>
              <div className="additional-info">
                <div className="info-box">Comprehensive HR Solutions</div>
                <div className="info-box">24/7 Customer Support</div>
              </div>
            </>
          }
        />
      </div>
    </section>
  );
};
