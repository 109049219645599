import React, { useState } from 'react';
import './ChoosePlan.css';

const ChoosePlan = () => {
  const [isYearly, setIsYearly] = useState(false);

  return (
    <div className="choose-plan">
     <h1>Choose A Plan</h1>
      <p>Created to Simplify Your Experience and Unlock the Advantages of HRMS and Project Management tools in one place</p>
      <div className="toggle-container">
        <span>Monthly</span>
        <div  className={`toggle-switch ${isYearly ? 'yearly-bg' : 'monthly-bg'}`}  onClick={() => setIsYearly(!isYearly)}>
          <div className={`toggle-knob ${isYearly ? 'yearly' : ''}`}></div>
        </div>
        <span>Yearly</span>
      </div>
      <div className="plans">
        <div className="plan">
          <h2>Basic</h2>
          <p>For small teams</p>
          <h3>${isYearly ? '199' : '19'} <span>per user, per {isYearly ? 'year' : 'month'}</span></h3>
          <ul>
            <li>HR Dashboard</li>
            <li>Employee Management</li>
            <li>Project Tracking</li>
            <li>Basic Reporting Tools</li>
            <li>Email Support</li>
          </ul>
          <button>Get Started Free</button>
        </div>
        <div className="plan populared">
          <h2>Premium</h2>
          <p>For growing teams</p>
          <h3>${isYearly ? '399' : '39'} <span>per user, per {isYearly ? 'year' : 'month'}</span></h3>
          <ul>
            <li>HR Dashboard</li>
            <li>Advanced Employee Management</li>
            <li>Project and Task Management</li>
            <li>Advanced Reporting Tools</li>
            <li>Priority Email Support</li>
          </ul>
          <button>Get Started Free</button>
        </div>
        <div className="plan">
          <h2>Enterprise</h2>
          <p>For large organizations</p>
          <h3>${isYearly ? '999' : '99'} <span>per user, per {isYearly ? 'year' : 'month'}</span></h3>
          <ul>
            <li>Comprehensive HRMS</li>
            <li>Complete Project Management Suite</li>
            <li>Custom Integrations</li>
            <li>Advanced Analytics</li>
            <li>Dedicated Support</li>
          </ul>
          <button>Get Started Free</button>
        </div>
      </div>
    </div>
  );
}

export default ChoosePlan;
