import React from 'react';
import './Card.css';
import fast from '../assets/fast.svg'
const Card = ({ title, description }) => {
  return (
    <div className="card">
      <div className="card-icon">
        <img src={fast} alt={fast}/>
      </div>
      <h2 className="card-title">{title}</h2>
      <p className="card-description">{description}</p>
    </div>
  );
};

export default Card;
