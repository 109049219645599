// src/components/Hero.js
import React from "react";
import "./Hero.css"; // Ensure the image path is correct
import Dashboard from "../assets/Dashboard.svg"; // src/components/Hero.js

const Hero = () => {
  return (
    <section id="hero">
      <div className="hero-background"></div>
      <div className="hero-content">
      <h1>Elevate Your HR Operations with Our Comprehensive Solutions</h1>
      <p>
          Streamline your hiring process, manage payroll, integrate vendor
          services, and utilize advanced HRMS tools with ease
        </p>
        <a href="#contact" className="cta-button">
          Get Started now
        </a>
      </div>
      <div className="dashboard-wrapper">
        <div className="translucent-tab">
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
        </div>
        <img src={Dashboard} alt="Dashboard" className="dashboard-img" />
      </div>
    </section>
  );
};

export default Hero;
