// src/App.js
import React from "react";
import Hero from "./components/Hero";
import AboutUs from "./components/AboutUs";
// import Services from "./components/Services";
import Testimonials from "./components/Testimonials";
// import Contact from "./components/Contact";
import "./App.css";
import Navbar from "./components/Navabar";
import { TrustCompanies } from "./components/TrustCompanies";
import ChoosePlan from "./components/ChoosePlan";
import FAQFooter from "./components/FaqFooter";
import { OurMission } from "./components/ourMission";
import FormDemo from "./components/FreeDemo";
// import CloudPeak from "./components/howWeWork";
function App() {
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <TrustCompanies />
      <AboutUs />
      <OurMission/>
      {/* <Services /> */}
      {/* <CloudPeak/> */}
      <ChoosePlan />
      <Testimonials />
      <FormDemo/>
      <FAQFooter />
      {/* <main> */}
      {/* <Contact /> */}
      {/* </main> */}
    </div>
  );
}

export default App;
