import ArisHeader from "../assets/arisHeader.svg";
import flipkart from "../assets/flipkart (2).svg";
import microsoft from "../assets/microsoft.svg";
import MassMutuallogo from '../assets/MassMutuallogo.svg';
import rythmos from '../assets/rythmos.svg';
import React from 'react';
import './TrustCompanies.css'; // Import the CSS file

export const TrustCompanies = () => {
  return (
    <div className="trust-companies">
      <p className="trust-text">
        Trusted by thousands of companies worldwide
      </p>
      <div className="trust-logos">
        <img src={ArisHeader} alt="Aris Header" />
        <img src={flipkart} alt="Flipkart" />
        <img src={microsoft} alt="Microsoft" />
        <img src={MassMutuallogo} alt="Mass Mutual" />
        <img src={rythmos} alt="Rythmos" />
      </div>
    </div>
  );
};

