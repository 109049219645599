import React, { useState } from "react";
import "./Navbar.css"; // Custom CSS for styling
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../assets/logo.png";
const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNavbar = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <header>
      <div style={{ display: "flex", alignItems: "center" }}>
        {" "}
        <img src={logo} alt="logo" height={50} width={80} /> Tech Butterfly
        Solutions
      </div>
      <nav className={isNavOpen ? "nav-links open" : "nav-links"}>
        <a href="#hero" onClick={toggleNavbar}>
          Home
        </a>
        <a href="#about-us" onClick={toggleNavbar}>
          About Us
        </a>
        <a href="#OurMission" onClick={toggleNavbar}>
          Services
        </a>
        <a href="#testimonials" onClick={toggleNavbar}>
          Testimonials
        </a>
        <a href="#contact" onClick={toggleNavbar}>
          Contact
        </a>
        <a
          href="#contact"
          className="navbar-button"
          style={{ textDecoration: "none" }}
          onClick={toggleNavbar}
        >
          Book a Free Demo
        </a>
      </nav>
      {isNavOpen ? (
        <button className="nav-btn nav-close-btn" onClick={toggleNavbar}>
          <FaTimes />
        </button>
      ) : (
        <button className="nav-btn" onClick={toggleNavbar}>
          <FaBars />
        </button>
      )}
    </header>
  );
};

export default Navbar;
